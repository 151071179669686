import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DateTime, DurationLikeObject, Settings as LuxonSettings } from 'luxon';
import { DeviceDashboardDialog } from 'src/app/dialogs/device-dashboard-dialog/device-dashboard-dialog.component';
import { ApiService } from 'src/app/services/api/api.service';
import { CustomerRole, urlBaseV2 } from 'src/app/services/constants';
import { DateTimeService } from 'src/app/services/date-time.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { hasRole } from 'src/app/shared/common';
import { LiveMapDevice } from 'src/app/shared/models/device';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-device-info',
    templateUrl: './device-info.component.html',
})
export class DeviceInfoComponent implements OnInit, OnChanges {
    @Input() device: LiveMapDevice;
    @Output() unselectDevice = new EventEmitter();

    isPinned = false;
    productImagePath = '';

    get showAlertsButton() {
        return hasRole(CustomerRole.OEM);
    }

    get showModelButton() {
        return this.device.modelId && hasRole(CustomerRole.OEM);
    }

    get dateTimeFormat() {
        return this.dateTimeService.dateTimeFormat;
    }

    get showUserIdentifier() {
        return !hasRole(CustomerRole.Dealer);
    }

    constructor(
        private dateTimeService: DateTimeService,
        private api: ApiService,
        private router: Router,
        private dialog: DialogService,
        private translate: TranslateService
    ) {}

    ngOnInit() {
        this.api.pins.listen();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.device) {
            if (this.device.modelId) {
                this.productImagePath =
                    urlBaseV2 + '/models/' + this.device.modelId + '/thumbnail';
            } else {
                this.productImagePath =
                    environment.apiUrl + '/images/Placeholder-model.png';
            }
            this.isPinned = this.api.pins.has('device', this.device.id);
        }
    }

    async openDashboard() {
        await this.dialog.open(DeviceDashboardDialog, {
            data: { device: this.device },
        });
    }

    goToModel() {
        this.router.navigate(['models', this.device.modelId]);
    }

    goToAlerts() {
        this.router.navigate(['alerts'], {
            queryParams: { filter: this.device.id },
        });
    }

    getRelativeHumanDate() {
        const lastOnline = DateTime.fromJSDate(this.device.lastOnline);
        let diff = lastOnline.diffNow(['seconds']).negate();
        if (diff.as('seconds') < 1) {
            return this.translate.instant('device.last_online.now');
        }
        let durationString = '';
        const units = ['seconds', 'minutes', 'hours', 'days', 'weeks'];
        for (let i = 0; i < units.length; i++) {
            const lastUnit = units[i - 1];
            const unit = units[i];
            const nextUnit = units[i + 1];
            diff = diff.shiftTo(unit as keyof DurationLikeObject);
            if (nextUnit && diff.as(nextUnit as keyof DurationLikeObject) < 1) {
                if (lastUnit) {
                    diff = diff.shiftTo(
                        unit as keyof DurationLikeObject,
                        lastUnit as keyof DurationLikeObject
                    );
                    if (diff.get(lastUnit as keyof DurationLikeObject) < 1) {
                        diff = diff.shiftTo(unit as keyof DurationLikeObject);
                    }
                }
                break;
            }
        }

        LuxonSettings.defaultLocale = this.translate.currentLang;
        durationString = diff.toHuman({ maximumFractionDigits: 0 });
        return this.translate.instant('common.duration', {
            duration: durationString,
        });
    }

    togglePin() {
        this.api.pins.toggle('device', this.device.id);
        this.isPinned = !this.isPinned;
    }
}
