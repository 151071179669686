import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { GoogleMap } from '@angular/google-maps';
import { DateTime } from 'luxon';
import { ApiService } from '../services/api/api.service';
import { MapsLoaderService } from '../services/maps-loader/maps-loader.service';
import { MqttService } from '../services/mqtt/mqtt.service';
import { SettingsService } from '../services/user/settings.service';
import { clone } from '../shared/common';
import { log } from '../shared/log';
import {
    Device,
    LiveMapDevice,
    OnlineCategory,
    hasValidLocation,
    onlineCategoryColor,
} from '../shared/models/device';
import { MqttClient } from '../shared/mqtt/mqtt';
import { darkModeStyles, lightModeStyles } from './map-styles';

// Devices will be considered "recently offline" after one minute
const DEFAULT_TIMEOUT_VALUE = 60_000;

@Component({ templateUrl: './home.component.html' })
export class HomeComponent implements OnInit, OnDestroy {
    client: MqttClient;
    selectedDeviceId = -1;
    devices: LiveMapDevice[] = [];
    deviceMap: Map<number, LiveMapDevice> = new Map();
    center: google.maps.LatLngLiteral = { lat: 38, lng: -97 };
    options = {
        styles: [],
        fullscreenControl: false,
        mapTypeControl: false,
        streetViewControl: false,
        zoomControl: false,
    };
    @ViewChild('map') map: GoogleMap;

    get selectedDevice(): LiveMapDevice | null {
        return this.deviceMap?.get(this.selectedDeviceId) ?? null;
    }

    constructor(
        private api: ApiService,
        private settings: SettingsService,
        public mapsLoader: MapsLoaderService,
        private mqttService: MqttService,
    ) {}

    // We don't use await in this method, so we don't block.
    // None of the loading operations depend on each other, so they don't need
    // to block each other.
    ngOnInit() {
        this.loadDevices();
        this.loadMap();
        this.watchDarkMode();
        this.connectMqtt();
    }

    ngOnDestroy() {
        if (this.client) {
            this.client.disconnect();
        }
        this.devices.forEach(({ setTimeoutRef }) =>
            this.clearTimeout(setTimeoutRef),
        );
    }

    async loadDevices() {
        this.api.devices.listenActive().subscribe((devices) => {
            this.devices = devices.map((d: Device) => ({
                ...d,
                lastOnlineStr: '',
                timeout: DEFAULT_TIMEOUT_VALUE,
                symbol: null,
                onlineCategory: 'online',
            }));
            this.devices.forEach((d) => this.setDeviceMarker(d, false));
            this.deviceMap = new Map(this.devices.map((d) => [d.id, d]));
        });
    }

    async loadMap() {
        await this.mapsLoader.load(['maps']);
        // Center the map on the user's location, if it's available
        navigator.geolocation?.getCurrentPosition((position) => {
            const lat = position['coords']['latitude'];
            const lng = position['coords']['longitude'];
            this.center = { lat, lng };
        });
    }

    watchDarkMode() {
        this.settings.values$.subscribe(() => {
            const isDarkMode = this.settings.get('extras.dark-mode');
            const newOptions = clone(this.options);
            newOptions.styles = isDarkMode ? darkModeStyles : lightModeStyles;
            this.options = newOptions;
        });
    }

    async connectMqtt() {
        if (this.client) {
            this.client.disconnect();
        }
        this.client = this.mqttService.createClient();
        this.client.abnormalDisconnect$.subscribe(() => this.connectMqtt());
        await this.client.connect();

        this.client
            .observeRoute('mrs/d/:deviceId/mon/#', 'mrs/d/+/mon/#', {
                parseJson: true,
            })
            .subscribe(({ packet, params }) => {
                const { topic, retain, json } = packet;
                if (retain) {
                    // Retained messages aren't useful for the live map.
                    return;
                }
                const deviceId = +params['deviceId'];
                const device = this.deviceMap.get(deviceId);
                if (!device) {
                    return;
                }
                const location = topic.endsWith('mon/location') ? json : null;
                if (location != null) {
                    device.lastLatitude = location['lat'];
                    device.lastLongitude = location['lon'];
                }
                this.setDeviceMarker(device, true);
            });
    }

    /**
     * Clear setTimeout
     * @param setTimeoutRef
     */
    private clearTimeout(setTimeoutRef: number) {
        if (setTimeoutRef) {
            clearTimeout(setTimeoutRef);
        }
    }

    private setDeviceTimeout(
        device: LiveMapDevice,
        category: OnlineCategory,
        timeout: number | null,
    ) {
        this.clearTimeout(device.setTimeoutRef);
        device.setTimeoutRef = setTimeout(
            () => this.setCategory(device, category),
            timeout ?? DEFAULT_TIMEOUT_VALUE,
        ) as unknown as number;
    }

    /**
     * Set device last time online and icon URL
     */
    private setDeviceMarker(device: LiveMapDevice, messageReceived: boolean) {
        const now = DateTime.utc();
        if (messageReceived) {
            this.setCategory(device, 'online');
            device.lastOnline = now.toJSDate();
            this.setDeviceTimeout(
                device,
                'recently-online',
                DEFAULT_TIMEOUT_VALUE,
            );
        } else {
            const msInDay = 86400000;
            const lastOnline = DateTime.fromJSDate(device.lastOnline);
            // Time offline in milliseconds
            const timeOffline = now.diff(lastOnline).as('milliseconds');
            if (lastOnline.isValid) {
                device.lastOnline = lastOnline.toJSDate();
            }

            // Change symbol color based on time offline
            if (timeOffline <= DEFAULT_TIMEOUT_VALUE) {
                this.setCategory(device, 'online');
                this.setDeviceTimeout(
                    device,
                    'recently-online',
                    DEFAULT_TIMEOUT_VALUE,
                );
            } else if (timeOffline <= msInDay) {
                this.setCategory(device, 'recently-online');
                this.setDeviceTimeout(device, 'offline', msInDay - timeOffline);
            } else {
                this.setCategory(device, 'offline');
            }
        }
    }

    setCategory(device: LiveMapDevice, category: OnlineCategory) {
        device.onlineCategory = category;
        this.updateSymbol(device);
    }

    updateSymbol(device: LiveMapDevice) {
        const isSelected = device.id == this.selectedDeviceId;
        device.symbol = {
            path: isSelected
                ? 'M 0 -13 M 0 -20 A 7 7 0 0 0 -7 -13 C -7 -7.75 0 0 0 0 C 0 0 7 -7.75 7 -13 A 7 7 0 0 0 0 -20 Z'
                : 'M 7 0 A 7 7 0 1 0 -7 0 A 7 7 0 1 0 7 0',
            fillColor: onlineCategoryColor(device.onlineCategory),
            fillOpacity: 1,
            strokeColor: 'white',
            strokeWeight: 1,
            scale: isSelected ? 2 : 1.2,
        };
    }

    selectDeviceId(deviceId: number | null) {
        const device = deviceId ? this.deviceMap.get(deviceId) : null;
        const alreadySelected = deviceId == this.selectedDeviceId;
        if (this.selectedDevice) {
            // Unselect the currently selected device first.
            const currentSelectedDevice = this.selectedDevice;
            this.selectedDeviceId = null;
            this.updateSymbol(currentSelectedDevice);
        }
        if (!alreadySelected) {
            this.selectedDeviceId = deviceId;
            if (deviceId) {
                this.updateSymbol(device);
                if (hasValidLocation(device)) {
                    const lat = device.lastLatitude;
                    const lng = device.lastLongitude;
                    this.map.panTo({ lat, lng });
                }
            }
        }
    }

    hasLocation(device: LiveMapDevice) {
        return hasValidLocation(device);
    }
}
