import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { FeedbackDialog } from 'src/app/dialogs/feedback/feedback.component';
import { ApiService } from 'src/app/services/api/api.service';
import { DateTimeService } from 'src/app/services/date-time.service';
import { DialogService } from 'src/app/services/dialog/dialog.service';
import { Announcement } from 'src/app/shared/models/announcement';

@Component({
    selector: 'app-announcements',
    templateUrl: './announcements.component.html',
})
export class AnnouncementsComponent implements OnInit {
    isCollapsed = true;
    count = 0;
    current?: Announcement;

    get currentMessage() {
        if (!this.current) {
            return this.translate.instant('announcement.unread.none');
        }
        const content = this.current.content;
        const langOptions = [
            this.translate.currentLang,
            'en',
            Object.keys(content)[0],
        ];
        const lang = langOptions.find((lang) => content[lang]);
        return content[lang];
    }

    constructor(
        private dialog: DialogService,
        private translate: TranslateService,
        public dateTimeService: DateTimeService,
        private api: ApiService,
    ) {}

    ngOnInit() {
        this.api.announcements
            .listen({ unread: true, isActive: true })
            .subscribe((unread) => {
                this.count = unread.length;
                this.isCollapsed = this.count == 0;
                this.current = unread[0] ?? null;
            });
    }

    read() {
        this.api.announcements.markAsRead(this.current.id);
    }

    async sendFeedback() {
        await this.dialog.open(FeedbackDialog, {
            data: {
                title: 'dialog.talk_to_us',
                placeholder: 'help.question.description',
                successMessage: 'dialog.thanks_for_feedback',
            },
        });
    }
}
