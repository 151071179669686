import { Injectable } from '@angular/core';
import { RouteMetadata, defaultRouteMetadata, routesMetadata } from './routes';
import * as pathern from 'pathern';

@Injectable({ providedIn: 'root' })
export class RouteMetadataService {
    private routesMetadata: RouteMetadata[] = routesMetadata;

    private getRouteMetadata(path: string): RouteMetadata {
        const route = this.routesMetadata.find(
            ({ route }) => route == path || pathern.matches(route, path),
        );
        return route ?? defaultRouteMetadata;
    }

    getCurrentPath(): string {
        const currentUrl = window.location.href;
        return new URL(currentUrl).pathname;
    }

    getIcon(path: string): string {
        return this.getRouteMetadata(path).icon;
    }

    getTitle(path: string): string {
        return this.getRouteMetadata(path).title;
    }
}
