<div
    class="pointer-events-auto flex h-fit w-full flex-col overflow-clip rounded-xl bg-white dark:bg-gray-900 sm:w-72"
>
    <div class="flex w-full flex-row items-center gap-1 p-2">
        <span class="grow text-sm opacity-80 dark:opacity-70">
            {{ 'device.info' | translate }}
        </span>
        <sz-icon-button
            icon="close"
            size="small"
            (click)="unselectDevice.emit()"
        />
    </div>
    <div class="flex flex-row sm:flex-col">
        <div
            class="flex grow flex-row items-center gap-3 truncate px-2 sm:px-3"
        >
            <div class="hidden sm:block">
                <sz-image
                    alt="model_editor.model_image"
                    class="h-12 w-12 min-w-[48px]"
                    classList="rounded-full"
                    [src]="productImagePath"
                />
            </div>
            <div class="flex flex-col truncate">
                <span
                    class="text-lg"
                    [matTooltip]="
                        showUserIdentifier
                            ? device.userIdentifier ?? device.identifier
                            : device.identifier
                    "
                >
                    <ng-container *ngIf="showUserIdentifier; else identifier">
                        {{ device.userIdentifier ?? device.identifier }}
                    </ng-container>
                    <ng-template #identifier>
                        {{ device.identifier }}
                    </ng-template>
                </span>
                <span class="text-base">{{ device.name }}</span>
            </div>
        </div>
        <div class="flex flex-row gap-1 pe-1 sm:mb-6 sm:p-2">
            <sz-icon-button
                *ngIf="device?.allowTelematics"
                id="dashboards-btn"
                icon="view-dashboard"
                size="large"
                matTooltip="{{ 'dashboard' | translate }}"
                (click)="openDashboard()"
            />
            <sz-icon-button
                *ngIf="showModelButton"
                id="model-btn"
                icon="bookmark-box-multiple"
                size="large"
                matTooltip="{{ 'model' | translate }}"
                (click)="goToModel()"
            />
            <sz-icon-button
                *ngIf="showAlertsButton"
                id="alerts-btn"
                icon="alert-decagram"
                size="large"
                matTooltip="{{ 'alert.plural' | translate }}"
                (click)="goToAlerts()"
            />
            <sz-icon-button
                id="pin-btn"
                [icon]="isPinned ? 'pin' : 'pin-outline'"
                size="large"
                [matTooltip]="(isPinned ? 'pin.unpin' : 'pin') | translate"
                (click)="togglePin()"
            />
        </div>
    </div>
    <div class="flex flex-col gap-2 p-2">
        <div class="flex flex-row justify-between gap-2">
            <div class="font-bold">
                {{ 'device.serial_number' | translate }}
            </div>
            <div>
                {{ device.serialNumber }}
            </div>
        </div>
        <div class="flex flex-row justify-between gap-2">
            <div class="font-bold">
                {{ 'customer.role.oem' | translate }}
            </div>
            <div>
                {{ device.oemName }}
            </div>
        </div>
        <div
            *ngIf="device.dealerName"
            class="flex flex-row justify-between gap-2"
        >
            <div class="font-bold">
                {{ 'customer.role.dealer' | translate }}
            </div>
            <div>
                {{ device.dealerName }}
            </div>
        </div>
        <div
            *ngIf="device.clientName"
            class="flex flex-row justify-between gap-2"
        >
            <div class="font-bold">
                {{ 'customer.role.client' | translate }}
            </div>
            <div>
                {{ device.clientName }}
            </div>
        </div>
        <div
            *ngIf="device.primarySoftwareVersion"
            class="flex flex-row justify-between gap-2"
        >
            <div class="font-bold">
                {{ 'device.software_version' | translate }}
            </div>
            <div>
                {{ device.primarySoftwareVersion }}
            </div>
        </div>
        <div class="flex flex-row justify-between gap-2">
            <div class="font-bold">
                {{ 'device.last_online' | translate }}
            </div>
            <div
                *ngIf="device.lastOnline; else unknown"
                [matTooltip]="device.lastOnline | date: [dateTimeFormat]"
            >
                {{ getRelativeHumanDate(device.lastOnline) }}
            </div>
            <ng-template #unknown>
                <div>
                    {{ 'common.unknown' | translate }}
                </div>
            </ng-template>
        </div>
    </div>
</div>
