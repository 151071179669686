import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { interval } from 'rxjs';
import { ApiService } from 'src/app/services/api/api.service';
import { CustomerRole, urlBaseV2 } from 'src/app/services/constants';
import { DateTimeService } from 'src/app/services/date-time.service';
import {
    LiveMapDevice,
    OnlineCategory,
    hasValidLocation,
    onlineCategoryColor,
} from 'src/app/shared/models/device';
import { environment } from 'src/environments/environment';
import { hasRole, isMobile } from '../../shared/common';

@Component({
    selector: 'app-home-device-list',
    templateUrl: './home-device-list.component.html',
})
export class HomeDeviceListComponent implements OnInit, OnChanges {
    @Input({ required: true }) devices: LiveMapDevice[] = [];
    @Input({ required: true }) selectedDeviceId: number;
    @Output() openDevice = new EventEmitter<number>();
    sectionCollapseState: Record<string, boolean> = {};
    productImagePaths: Record<number, string> = {};
    defaultPath = environment.apiUrl + '/images/Placeholder-model.png';
    isCollapsed = isMobile();
    // The search string to filter devices by.
    deviceFilter = '';
    // An array of pinned device IDs, sorted by their pin order.
    pinnedDeviceOrder = [];
    // Identify the four sections.
    sectionIds = ['pinned', 'online', 'recently-online', 'offline'];
    // The section device arrays.
    sections: LiveMapDevice[][] = [[], [], [], []];

    get showUserIdentifier() {
        return !hasRole(CustomerRole.Dealer);
    }

    constructor(
        public dateTimeService: DateTimeService,
        private api: ApiService,
    ) {}

    async ngOnInit() {
        this.api.pins.listen({ type: 'device' }).subscribe((pins) => {
            this.pinnedDeviceOrder = pins
                .sort((a, b) => a.order - b.order)
                .map((p) => p.itemId);
            this.updateDeviceList();
        });
        // Update device list once a second.
        // If we update the order in ngOnChanges, the list ordering will change
        // every time an MQTT message comes in, creating a jittery UX.
        interval(1000).subscribe(() => this.updateDeviceList());
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.devices) {
            this.productImagePaths = {};
            for (const device of this.devices) {
                if (device.modelId) {
                    this.productImagePaths[device.modelId] =
                        urlBaseV2 + '/models/' + device.modelId + '/thumbnail';
                }
            }
        }
    }

    deviceFilterChange() {
        if (this.deviceFilter) {
            this.isCollapsed = false;
        }
    }

    updateDeviceList() {
        const categoryOrder = ['online', 'recently-online', 'offline'];
        const sections = [[], [], [], []];
        Array.from(this.devices)
            .sort((a: LiveMapDevice, b: LiveMapDevice) => {
                const aIsPinned = this.pinnedDeviceOrder.includes(a.id);
                const bIsPinned = this.pinnedDeviceOrder.includes(b.id);
                if (aIsPinned && bIsPinned) {
                    // Sort pinned devices by their order.
                    return (
                        this.pinnedDeviceOrder.indexOf(a.id) -
                        this.pinnedDeviceOrder.indexOf(b.id)
                    );
                } else if (aIsPinned != bIsPinned) {
                    // Put pinned devices above non-pinned devices.
                    return aIsPinned ? -1 : 1;
                }
                if (a.onlineCategory == b.onlineCategory) {
                    let aIdentifier = a.identifier;
                    let bIdentifier = b.identifier;
                    if (this.showUserIdentifier) {
                        aIdentifier = a.userIdentifier ?? a.identifier;
                        bIdentifier = b.userIdentifier ?? b.identifier;
                    }
                    return aIdentifier.localeCompare(bIdentifier);
                }
                // Sort by category.
                return (
                    categoryOrder.indexOf(a.onlineCategory) -
                    categoryOrder.indexOf(b.onlineCategory)
                );
            })
            .forEach((device) => {
                const sectionIndex = this.pinnedDeviceOrder.includes(device.id)
                    ? 0
                    : categoryOrder.indexOf(device.onlineCategory) + 1;
                sections[sectionIndex].push(device);
            });
        this.sections = sections;
    }

    noLocation(device: LiveMapDevice) {
        return !hasValidLocation(device);
    }

    sectionDotColor(sectionId: string) {
        if (sectionId == 'pinned') {
            return 'GoldenRod';
        }
        return onlineCategoryColor(sectionId as OnlineCategory);
    }

    toggleSectionCollapse(sectionId) {
        this.sectionCollapseState[sectionId] =
            !this.sectionCollapseState[sectionId];
    }

    isSectionCollapsed(sectionId: string): boolean {
        return this.sectionCollapseState[sectionId] || false;
    }

    sectionTitle(sectionId: string) {
        switch (sectionId) {
            case 'pinned':
                return 'pin.section.title';
            case 'online':
                return 'device.online_category.online';
            case 'recently-online':
                return 'device.online_category.recently_online';
            case 'offline':
                return 'device.online_category.offline';
        }
    }
}
