<h1 mat-dialog-title>{{ 'dialog.select_dashboard' | translate }}</h1>
<div class="mat-typography" mat-dialog-content>
    <form [formGroup]="form" class="flex flex-col items-center gap-2">
        <sz-select
            class="w-full"
            formControlName="dashboardId"
            [options]="options"
        />
        <mat-checkbox formControlName="newTab">{{
            'dialog.new_tab' | translate
        }}</mat-checkbox>
    </form>
    <sz-dialog-button-bar
        primaryText="btn.goto"
        tertiaryText="common.new"
        [primaryDisabled]="!form.valid"
        [tertiaryHidden]="false"
        (primaryClick)="confirm()"
        (secondaryClick)="dialogRef.close()"
        (tertiaryClick)="create()"
    />
</div>
