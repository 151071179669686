<div class="scroll-content-no-tool-bar relative">
    <google-map
        *ngIf="mapsLoader.isLoaded"
        #map
        width="100%"
        height="100%"
        [center]="center"
        [zoom]="5"
        [options]="options"
    >
        <map-marker
            *ngFor="let device of devices | filter : hasLocation"
            #deviceMarker
            [position]="{ lat: device.lastLatitude, lng: device.lastLongitude }"
            [options]="{ icon: device.symbol }"
            (mapClick)="selectDeviceId(device.id)"
        />
    </google-map>
    <app-home-overlays
        [selectedDeviceId]="selectedDeviceId"
        [devices]="devices"
        (selectedDeviceIdChanged)="selectDeviceId($event)"
    />
</div>
