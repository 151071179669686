import { Injectable } from '@angular/core';
import { ClientOptions, MqttClient } from 'src/app/shared/mqtt/mqtt';

// This allows us to use fake MQTT clients in unit tests.
@Injectable({ providedIn: 'root' })
export class MqttService {
    createClient(options?: ClientOptions): MqttClient {
        return new MqttClient(options);
    }
}
