import { RouterIcon } from 'src/app/root/model/root.model';

export interface RouteMetadata {
    route: string;
    title: string;
    icon: string;
}

export const defaultRouteMetadata: RouteMetadata = {
    route: '',
    title: 'common.unknown',
    icon: RouterIcon.DEFAULT,
};

export const routesMetadata: RouteMetadata[] = [
    { route: '/about', title: 'about', icon: RouterIcon.ABOUT },
    { route: '/about/roadmap', title: 'roadmap', icon: RouterIcon.ROADMAP },
    { route: '/alerts', title: 'alert.plural', icon: RouterIcon.ALERTS },
    { route: '/alerts/:id', title: 'alert', icon: RouterIcon.ALERTS },
    {
        route: '/announcements',
        title: 'announcement.plural',
        icon: RouterIcon.ANNOUNCEMENTS,
    },
    {
        route: '/announcements/:id',
        title: 'announcement',
        icon: RouterIcon.ANNOUNCEMENTS,
    },
    { route: '/billing/:id', title: 'bill.billing', icon: RouterIcon.CASH },
    {
        route: '/dashboards',
        title: 'dashboard.plural',
        icon: RouterIcon.DASHBOARDS,
    },
    {
        route: '/dashboards/overview',
        title: 'utilities.dashboard_overview',
        icon: RouterIcon.DASHBOARD_OVERVIEW,
    },
    {
        route: '/dashboards/:id',
        title: 'dashboard',
        icon: RouterIcon.DASHBOARDS,
    },
    {
        route: '/data-files',
        title: 'title.data_files',
        icon: RouterIcon.DATA_FILES,
    },
    {
        route: '/data-files/:id',
        title: 'title.data_files',
        icon: RouterIcon.DATA_FILES,
    },
    {
        route: '/data-files/:id/charts',
        title: 'title.analyze_trace_log',
        icon: RouterIcon.DATA_FILES,
    },
    // TODO: remove this when /data-files is ready for production
    {
        route: '/data-files-old',
        title: 'title.data_files',
        icon: RouterIcon.DATA_FILES,
    },
    // TODO: remove this when /data-files is ready for production
    {
        route: '/data-files-alt',
        title: 'title.data_files',
        icon: RouterIcon.DATA_FILES,
    },
    {
        route: '/device-connections',
        title: 'device.connections',
        icon: RouterIcon.DEVICES_CONNECTION_LOG,
    },
    {
        route: '/device-simulators',
        title: 'utilities.simulator',
        icon: RouterIcon.SIMULATOR,
    },
    { route: '/devices', title: 'device.plural', icon: RouterIcon.DEVICES },
    { route: '/devices/:id', title: 'device', icon: RouterIcon.DEVICES },
    {
        route: '/devices/:id/connection-log',
        title: 'device.connection_log',
        icon: RouterIcon.DEVICES_CONNECTION_LOG,
    },
    { route: '/geo-fences', title: 'geo_fence', icon: RouterIcon.GEO_FENCING },
    {
        route: '/geo-fences/:id',
        title: 'geo_fence.plural',
        icon: RouterIcon.GEO_FENCING,
    },
    { route: '/help', title: 'common.help', icon: RouterIcon.HELP },
    { route: '/help/:doc', title: 'common.help', icon: RouterIcon.HELP },
    { route: '/home', title: 'common.home', icon: RouterIcon.HOME },
    { route: '/models', title: 'model.plural', icon: RouterIcon.MODELS },
    { route: '/models/:id', title: 'model', icon: RouterIcon.MODELS },
    {
        route: '/models/:modelId/can-databases/:id',
        title: 'can_database.edit',
        icon: RouterIcon.DEFAULT,
    },
    {
        route: '/models/:modelId/software-versions',
        title: 'title.software_versions',
        icon: RouterIcon.SOFTWARE_VERSIONS,
    },
    { route: '/modules', title: 'module.plural', icon: RouterIcon.MODULES },
    {
        route: '/modules/:id',
        title: 'module.release.plural',
        icon: RouterIcon.RELEASES,
    },
    {
        route: '/organizations',
        title: 'organization.plural',
        icon: RouterIcon.ORGANIZATIONS,
    },
    {
        route: '/organizations/:id',
        title: 'title.edit_organization',
        icon: RouterIcon.ORGANIZATIONS,
    },
    {
        route: '/page-views',
        title: 'utilities.page_views',
        icon: RouterIcon.PAGE_VIEWS,
    },
    { route: '/reports', title: 'report.plural', icon: RouterIcon.REPORTS },
    { route: '/reports/:id', title: 'report.plural', icon: RouterIcon.REPORTS },
    {
        route: '/statistics',
        title: 'statistic.plural',
        icon: RouterIcon.STATISTICS,
    },
    {
        route: '/statistics/:id',
        title: 'statistic.plural',
        icon: RouterIcon.STATISTICS,
    },
    {
        route: '/statistics/device/:id',
        title: 'device_statistic',
        icon: RouterIcon.STATISTICS,
    },
    { route: '/settings', title: 'setting.plural', icon: RouterIcon.SETTINGS },
    { route: '/users', title: 'user.plural', icon: RouterIcon.USERS },
    { route: '/users/:id', title: 'user', icon: RouterIcon.USERS },
    {
        route: '/widgets',
        title: 'custom_widget.plural',
        icon: RouterIcon.CUSTOM_WIDGETS,
    },
    {
        route: '/widgets/:id',
        title: 'custom_widget',
        icon: RouterIcon.CUSTOM_WIDGETS,
    },
];
