<div
    class="pointer-events-auto flex max-h-[160px] w-full flex-col overflow-clip rounded-xl bg-white dark:bg-gray-900 sm:max-h-[440px] sm:w-72"
    [style.min-height]="isCollapsed ? '40px' : '120px'"
>
    <sz-overlay-title-bar
        [isCollapsed]="isCollapsed"
        (toggleCollapsed)="isCollapsed = !isCollapsed"
    >
        <span class="grow text-start text-sm opacity-80 dark:opacity-70">
            {{ 'common.shortcuts' | translate }}
        </span>
    </sz-overlay-title-bar>
    <div
        class="flex w-full grow flex-col overflow-y-auto"
        [class.hidden]="isCollapsed"
    >
        <div class="mx-2 grow flex justify-between opacity-80 dark:opacity-70">
            <div class="flex gap-2 text-start text-sm items-center">
                <mat-icon
                    class="!h-4 !w-4"
                    [style.color]="'goldenrod'"
                    svgIcon="pin"
                />
                {{ 'pin.section.title' | translate }}
            </div>
            <mat-icon
                class="!h-6 !w-6 cursor-pointer rounded-lg hover:bg-gray-700/20 dark:hover:bg-gray-400/20"
                [ngClass]="
                    isDraggable ? 'text-green-700 dark:text-green-300' : ''
                "
                [svgIcon]="isDraggable ? 'check' : 'swap-vertical'"
                (click)="toggleDraggable()"
            />
        </div>
        @if (!isDraggable) {
            <div>
                @for (shortcut of shortcuts.pinned; track shortcut.url) {
                    <app-shortcut-item
                        [shortcut]="shortcut"
                        (removePin)="removePin(shortcut.pinId)"
                    />
                }
            </div>
        } @else {
            <div cdkDropList (cdkDropListDropped)="drop($event)">
                @for (shortcut of shortcuts.pinned; track shortcut.url) {
                    <div
                        class="opacity-100 bg-white rounded-lg dark:bg-gray-900"
                        cdkDrag
                    >
                        <div
                            class="h-0.5 bg-secondary"
                            *cdkDragPlaceholder
                        ></div>
                        <app-shortcut-item
                            [isDraggable]="isDraggable"
                            [shortcut]="shortcut"
                        />
                    </div>
                }
            </div>
        }
        <div
            class="mt-4 mx-2 grow flex items-center gap-2 text-start text-sm opacity-80 dark:opacity-70"
        >
            <mat-icon
                class="!h-4 !w-4"
                [style.color]="'goldenrod'"
                svgIcon="sort-variant"
            />
            {{ 'common.top_viewed' | translate }}
        </div>
        @if (isIdentifiedTrackingAllowed) {
            @for (shortcut of shortcuts.topViewed; track shortcut.url) {
                <app-shortcut-item [shortcut]="shortcut" />
            }
        } @else {
            <div class="m-3 grow text-start">
                {{ 'settings.identified_tracking.off' | translate }}
            </div>
            <a
                class="mx-3 grow text-center dark:text-primary-200 text-primary-700 hover:underline"
                routerLink="/settings"
            >
                {{ 'settings.go_to' | translate }}
            </a>
        }
    </div>
</div>
