<div
    class="flex h-10 flex-row items-center gap-2 truncate px-2"
    [ngClass]="isDraggable ? 'cursor-pointer' : ''"
>
    <a
        class="flex grow items-center gap-2 truncate text-gray-700 dark:text-gray-100"
        [ngClass]="isDraggable ? 'pointer-events-none' : ''"
        [routerLink]="shortcut.url"
    >
        <div class="h-6 w-6 min-w-[24px]">
            <mat-icon class="!h-full !w-full" [svgIcon]="shortcut.icon" />
        </div>
        <span class="grow text-lg">
            {{ shortcut.name | translate }}
        </span>
    </a>
    @if (shortcut.pinId != null && !isDraggable) {
        <button
            class="flex h-6 w-6 min-w-[24px] cursor-pointer items-center justify-center rounded-md hover:bg-gray-700/20 focus:bg-gray-700/20 dark:hover:bg-gray-400/20 dark:focus:bg-gray-400/20"
            (click)="removePin.emit()"
        >
            <mat-icon class="!h-5 !w-5 text-[goldenrod]" svgIcon="pin" />
        </button>
    }
    @if (isDraggable) {
        <div class="flex h-6 w-6 min-w-[24px]">
            <mat-icon class="!h-5 !w-5" svgIcon="drag-horizontal" />
        </div>
    }
</div>
